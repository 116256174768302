<script>
export default {
  name: 'RichTextEditor',
  components: {
    ValidationMessage: () => import('@/components/general/ValidationMessage')
  },

  props: {
    description: {
      type: String,
      default: null
    },

    options: {
      type: Object,
      default: () => {
        return {
          modules: {
            clipboard: {
              matchVisual: false
            }
          }
        }
      }
    },

    toolbar: {
      type: Array,
      default: () => [
        // [{ 'font': [] }, { 'size': ['small', false, 'large', 'huge'] }],
        // [{ 'size': ['small', false, 'large', 'huge'] }],
        [
          {
            size: [
              'small',
              false,
              'large'
            ]
          }
        ],
        [
          {
            color: [
              '#707070',
              '#005EB8'
            ]
          }
        ],
        [
          'bold',
          'italic',
          'underline',
          'strike'
        ],
        [
          { align: '' },
          { align: 'center' },
          { align: 'right' },
          { align: 'justify' }
        ],
        [ 'clean' ]
        //  ['link'],
        //  [{'list': 'ordered'}, { 'list': 'bullet' }, { 'list': 'check' }]
      ]
    },

    value: null,
    validation: {
      type: Object,
      default: () => { return {} }
    },

    disabled: {
      type: Boolean,
      default: false
    },

    dark: {
      type: Boolean,
      default: false
    },

    underDescription: {
      type: [
        String,
        Array
      ],

      default: null
    }
  },

  data () {
    return {
      mutableValue: this.value
    }
  },

  computed: {
    isRequired () {
      return this.validation.$params && typeof this.validation.$params.required === 'object'
    }
  },

  watch: {
    value (value) {
      this.mutableValue = this.cleanQlCursor(value)
    },

    'mutableValue' () {
      this.updateValue()
      this.$emit('input', this.mutableValue)
    }
  },

  methods: {
    updateValue (value = this.mutableValue) {
      typeof this.validation.$touch === 'function' && this.validation.$touch()
      this.$emit('input', value)
    },

    cleanQlCursor (value) {
      return value.replaceAll(/<span class="ql-cursor">.*<\/span>/g, '')
    }
  }
}
</script>

<template>
  <div
    class="form-item form-item-rich-text-box"
    :class="{ 'has-error': validation.$error, 'theme-dark': dark, 'has-underdescription': underDescription }"
  >
    <p
      v-if="description"
      class="form-item-description"
    >
      {{ description }} <span v-if="!isRequired && !disabled">{{ $t('global:form.optional') }}</span>
    </p>
    <div
      v-if="underDescription && typeof underDescription === 'string'"
      class="form-input-subtext"
    >
      <p
        class="form-input-subtext"
        v-html="underDescription"
      />
    </div>
    <div
      v-if="underDescription && typeof underDescription === 'object'"
      class="form-input-subtext"
    >
      <p
        v-for="(paragraph, paragraphIndex) in underDescription"
        :key="paragraphIndex"
        class="form-input-subtext"
        v-html="paragraph"
      />
    </div>
    <VueEditor
      v-model="mutableValue"
      :disabled="disabled"
      :editor-toolbar="toolbar"
      :editor-options="options"
    />
    <div
      v-if="validation.$error"
      class="form-input-details"
    >
      <ValidationMessage :validation="validation" />
    </div>
  </div>
</template>

<style src="@/assets/styles/themes/default/form.css"></style>
